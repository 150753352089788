<script setup lang="ts">
const { error, story } = await useStoryblokRouter();

if (error?.value) {
	throw createError({ statusCode: 404, message: "Page not found", cause: error, fatal: true });
}
</script>

<template>
	<div>
		<StoryblokComponent v-if="story" :blok="story.content!" />
	</div>
</template>
